import _get from 'lodash/get';

import { lowerCaseQueryParam } from '..';
import getSelectedTabFromTabName from './getSelectedTabFromTabName';
import { dealerTabsDuck } from '../../ducks';

export const getSelectedTabFromState = (state) => {
    const tabData = dealerTabsDuck.selectors.getDuckState(state);
    let selectedTab = _get(tabData, 'selected', null);
    if (!selectedTab || !selectedTab.name) {
        const tabs = _get(tabData, 'tabs', []);
        const tabName = _get(lowerCaseQueryParam(_get(state, 'router.location.query', {})), 'dealerdetailstabs');
        selectedTab = getSelectedTabFromTabName(tabs, tabName);
    }
    return selectedTab;
};

export default getSelectedTabFromState;
