import { pageNames } from '@atc/bonnet-paths';

import { objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    namespace: pageNames.DEALER_DETAILS,
    store: 'activeInteraction',
    storePrefix: `${pageNames.DEALER_DETAILS}.`,
    initialState: {
        ownerId: null,
        parentId: null,
        showEmailModal: false,
    },
});
