/* eslint-disable no-useless-escape */

import { getPath as getPathFromBonnet } from '@atc/bonnet-paths';
import appendSlash from './appendSlash';

export default async function getPath(pageName, data, ext) {
    const { brand } = ext;
    // remove unused nextjs params
    delete data.slug;

    const path = await getPathFromBonnet(pageName, data, ext);
    const newPath = appendSlash(path, brand);

    return newPath;
}
