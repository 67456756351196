import _get from 'lodash/get';
import { getSelectedTabFromState } from '.';
import { TAB_ANALYTICS_NAME, kbbBrand } from '../constants';

const getTabName = (state) => {
    const detailTabName = getSelectedTabFromState(state)?.name || _get(state, 'router.location.query.dealerdetailstabs', '');
    return TAB_ANALYTICS_NAME[detailTabName] || (state?.brand === kbbBrand ? TAB_ANALYTICS_NAME.reviews : TAB_ANALYTICS_NAME.sales);
};

export default getTabName;
