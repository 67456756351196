import { get as _get, trim as _trim, lowerCase as _lowerCase, startCase as _startCase } from 'lodash';
import { createSelector } from '@atc/modular-redux';
import { pageNames } from '@atc/bonnet-paths';
import {
    userDuck, ownersDuck,
    dealerFiltersDuck,
    dealerSetMakeDuck,
    serviceDealerDuck,
    inventoryResultsDuck,
    inventoryActiveOwnerDuck,
} from '../ducks';
import { allMakeValue, kbbBrand } from '../constants';
import { formatSpaces, getCityZipCode, getPath, isNoIndex, getBreadcrumbsForFLD, transformsMake } from '../utilities';

const builders = {

    [pageNames.DEALER_SEARCH]: createSelector(
        userDuck.selectors.getLocation,
        userDuck.selectors.getCityState,
        dealerFiltersDuck.selectors.getLabelsByValues,
        dealerFiltersDuck.selectors.getFiltersValues,
        dealerSetMakeDuck.selectors.getDuckState,
        (location, cityState, labelsByValues, filtersValues, kbbMakeFilter) => {
            if (!kbbMakeFilter || kbbMakeFilter === allMakeValue) {
                kbbMakeFilter = '';
            }
            const listingTypesValue = [].concat(_get(filtersValues, 'listingType'));

            let makeLabel;
            let listingTypeLabel = '';

            if (kbbMakeFilter.length > 0) {
                makeLabel = transformsMake(kbbMakeFilter);
            }

            if (listingTypesValue.length === 1) {
                listingTypeLabel = _get(labelsByValues, ['listingType', listingTypesValue]);
            }

            const locationLabel = cityState ? `in ${cityState} ${location.zip}` : 'Nationwide';
            const locationHeading = cityState ? `in ${cityState}` : 'Nationwide';
            const withLocationDescForKbb = [
                `Find a local ${location.city} ${kbbMakeFilter || 'car'} dealer`,
                'to search for your next new or used car.',
                `Browse Kelley Blue Book's list of car dealerships near ${location.city}.`,
            ];
            const nationwideDescForKbb = [
                `Find a local ${kbbMakeFilter || 'car'} dealer`,
                'to search for your next new or used car.',
                'Browse Kelley Blue Book\'s list of car dealerships.',
            ];
            const withLocationTitleForKbb = [
                `${kbbMakeFilter || 'Car'} Dealerships`,
                `in ${cityState} ${location.zip} | Kelley Blue Book`,
            ];
            const nationwideTitleForKbb = [
                `${kbbMakeFilter || 'Car'} Dealerships -`,
                `Find a Local ${kbbMakeFilter || 'Car'} Dealer Near You | Kelley Blue Book`,
            ];
            const withLocationHeadingForKbb = [
                `Find ${location?.zip?.length > 0 && kbbMakeFilter?.length === 0 ? 'Local' : kbbMakeFilter}`,
                `${location?.city?.length > 0 ? '' : kbbMakeFilter}`,
                `Dealer in ${location.city}, ${location.state}`,
            ];

            const nationwideHeadingForKbb = [
                'Find',
                kbbMakeFilter,
                'Dealers Nationwide'.replace(/\s+/g, ' '),
            ];

            return {
                title: ['All', listingTypeLabel, makeLabel, 'Dealers', locationLabel],
                description: ['Find', location.city, listingTypeLabel, makeLabel, 'Dealers.', 'Search for all', listingTypeLabel, location?.zip?.length > 0 ? makeLabel : '', 'dealers', locationLabel, 'and view their inventory at Autotrader'],
                heading: ['All', listingTypeLabel, makeLabel, 'Dealers', locationHeading],
                descriptionForKbb: cityState ? withLocationDescForKbb : nationwideDescForKbb,
                titleForKbb: cityState ? withLocationTitleForKbb : nationwideTitleForKbb,
                headingForKbb: cityState ? withLocationHeadingForKbb : nationwideHeadingForKbb,
            };
        }
    ),

    [pageNames.DEALER_DETAILS]: createSelector(
        (state) => inventoryActiveOwnerDuck.selectors.getDuckState(state),
        ownersDuck.selectors.getOwners,
        (state) => inventoryResultsDuck.selectors.getResultsCount(state),
        serviceDealerDuck.selectors.getDuckState,
        (activeOwner, owners, inventoryListing, serviceDealer) => {
            const { name, location = {}, rating = {} } = _get(owners, activeOwner, {});
            const { value: kbbRating = 0 } = rating;
            const { city, state } = _get(location, 'address', {});
            const formatName = formatSpaces(name);
            const formatCity = _startCase(_lowerCase(_trim(city)));

            let atcNewTitle = '';
            let kbbNewTitle = '';

            const kbbTitleBuilder = (kbbRating < 4 ? ' | Kelley Blue Book' : ` | Rated ${kbbRating} Stars | Kelley Blue Book`);
            const inventoryListingShowcase = inventoryListing <= 1000 ? `${inventoryListing}` : '1000-plus';
            const atcTitleBuilder = (inventoryListing < 10 ? ' | Cars Available | Autotrader' : ` | ${inventoryListingShowcase} Cars Available | Autotrader`);

            if (!city && !state) {
                atcNewTitle = `${formatName}${atcTitleBuilder}`;
                kbbNewTitle = `${formatName}${kbbTitleBuilder}`;
            }
            if (city && state) {
                const titleWithLocation = `${formatName} in ${formatCity}, ${state}`;
                atcNewTitle = `${titleWithLocation}${atcTitleBuilder}`;
                kbbNewTitle = `${titleWithLocation}${kbbTitleBuilder}`;
            }

            return {
                titleForAtc: [atcNewTitle],
                descriptionForAtc: [`View new, used and certified cars in stock. Get a free price quote, or learn more about ${formatName} amenities and services.`],
                titleForKbb: [kbbNewTitle],
                descriptionForKbb: [`View KBB ratings and reviews for ${formatName}. See hours, photos, sales${serviceDealer.isPayingDealer ? ' and service' : ''} department info and more.`],
            };
        }
    ),
};

const setMetadataForDDP = (isKbb, metadata) => {
    metadata.title = isKbb ? metadata.titleForKbb : metadata.titleForAtc;
    metadata.description = isKbb ? metadata.descriptionForKbb : metadata.descriptionForAtc;
    metadata.heading = isKbb ? metadata.headingForKbb : metadata.heading;
    return {
        title: metadata.title,
        description: metadata.description,
        heading: metadata.heading,
    };
};

const setMetadataForFYD = (isKbb, metadata) => {
    metadata.title = isKbb ? metadata.titleForKbb : metadata.title;
    metadata.description = isKbb ? metadata.descriptionForKbb : metadata.description;
    metadata.heading = isKbb ? metadata.headingForKbb : metadata.heading;
    return {
        title: metadata.title,
        description: metadata.description,
        heading: metadata.heading,
    };
};

const setHeadingAndTitleForData = (isKbb, data) => {
    if (isKbb) {
        // remove 'All' on title and heading metadata
        data.heading.splice(0, 1);
        data.title.splice(0, 1);
    }
    return { dataHeading: data.heading, dataTitle: data.title };
};

export default function getMetadata() {
    return async (ctx) => {
        const state = ctx.store.getState();
        const data = builders[ctx.pageName](state);
        let metadata = Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: data[key].filter(Boolean).join(' ') }), {});
        const isKbb = ctx.data.brand === kbbBrand;

        const canonicalQuery = { ...ctx.query };
        if (ctx.originalQuery?.seoLocation) {
            // Handle city center zip code for city/state
            try {
                canonicalQuery.zip = await getCityZipCode(ctx.query);
            } catch (e) {
                console.log(e);
            }
        } else {
            delete canonicalQuery.zip;
        }

        // ensure we use a copy of query here so as to not mutate the context's query
        metadata.canonical = await getPath(ctx.pageName, { ...canonicalQuery }, { absolute: true, req: ctx.req, search: false, brand: ctx.data.brand });
        // Handle makeCode
        if (isKbb && ctx.query.make && ctx.query.make !== allMakeValue) {
            ctx.query.seoMakeCode = ctx.query.make.replace(' ', '-').toLowerCase();
        }

        if (ctx.pageName === pageNames.DEALER_SEARCH) {
            metadata.noIndex = isNoIndex({ pageName: ctx.pageName, pagePath: metadata.canonical });
            const { dataTitle, dataHeading } = setHeadingAndTitleForData(isKbb, data);
            data.title = dataTitle;
            data.heading = dataHeading;
            const { title, description, heading } = setMetadataForFYD(isKbb, metadata);
            const location = _get(state, 'user.location', {});
            const breadcrumbs = await getBreadcrumbsForFLD(canonicalQuery.make, location, ctx.data.brand);
            metadata.fldBreadcrumbs = breadcrumbs;

            metadata = { ...metadata, title, description, heading };

            if (!isKbb) {
                metadata.title = `${metadata.title} - Autotrader`;
            }
        }

        if (ctx.pageName === pageNames.DEALER_DETAILS) {
            const inventoryCount = inventoryResultsDuck.selectors.getResultsCount(state) || 0;
            metadata.noIndex = isNoIndex({ pageName: ctx.pageName, hasInventory: inventoryCount > 0 });
            const currentTitle = _get(state, `domain.${pageNames.DEALER_DETAILS}.metadata.title`);
            const { title, description, heading } = setMetadataForDDP(isKbb, metadata);
            metadata = { ...metadata, title: currentTitle || title, description, heading };
        }

        ctx.data = {
            ...ctx.data,
            ...metadata,
        };
    };
}
