const removeMakeModels = (makes) => {
    makes?.forEach((make) => {
        // delete models object
        delete make.models;
    });

    return makes;
};

export default removeMakeModels;
