import { createAction, stringDuckCreator } from '@atc/modular-redux';

export default function showContactDealerCreator({
    namespace = '',
    store = 'showContactDealer',
    storePrefix,
    initialState = { isSsuccess: false, mesage: '', showing: false },
} = {}) {
    return stringDuckCreator({
        initialState,
        namespace,
        store,
        storePrefix,
    }).extend({
        creators: ({ types }) => ({
            setShowingContactDealer: createAction(types.SET),
        }),
    });
}
