import React from 'react';
import dynamic from 'next/dynamic';
import { kbbBrand } from '../constants';

const AtcTheme = dynamic(() => import('./AtcTheme'));
const KbbTheme = dynamic(() => import('./KbbTheme'));

export default function SassTheme(props) {
    if (props.brand === kbbBrand) {
        return <KbbTheme />;
    }

    return <AtcTheme />;
}
