const transformsHoursFromServiceDealer = (departments = []) => {
    let tempArray = [];
    const groupHours = [];
    const defaultHours = [
        { close: '', open: '', label: 'Monday' },
        { close: '', open: '', label: 'Tuesday' },
        { close: '', open: '', label: 'Wednesday' },
        { close: '', open: '', label: 'Thursday' },
        { close: '', open: '', label: 'Friday' },
        { close: '', open: '', label: 'Saturday' },
        { close: '', open: '', label: 'Sunday' },
    ];
    if (departments.length === 0) {
        return;
    }
    for (let i = 0; i < departments.length; i++) {
        tempArray.push(departments[i]);
        if (tempArray.length > 1) {
            groupHours.push(tempArray);
            tempArray = [];
        }
    }

    return defaultHours.reduce((map, item, i) => {
        map.push({ ...item, open: groupHours[i][0], close: groupHours[i][1] });
        return map;
    }, []);
};
export default transformsHoursFromServiceDealer;
