import _get from 'lodash/get';
import { dealerTabsDuck } from '../../ducks';

const getTabIndexByName = (state, tabName) => {
    const tabData = dealerTabsDuck.selectors.getDuckState(state);
    const tabs = _get(tabData, 'tabs', []);
    return tabs.findIndex((tab) => tab.name === tabName);
};

export default getTabIndexByName;
