import { pageNames } from '@atc/bonnet-paths';

import { objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    namespace: pageNames.DEALER_SEARCH,
    store: 'metadata',
    storePrefix: `${pageNames.DEALER_SEARCH}.`,
    initialState: {
        title: '',
        description: '',
        canonical: '',
    },
});
