import { createAction, stringDuckCreator } from '@atc/modular-redux';

export default function setMakeDuckCreator({
    namespace = '',
    store = 'make',
    storePrefix,
    initialState = '',
} = {}) {
    return stringDuckCreator({
        initialState,
        namespace,
        store,
        storePrefix,
    }).extend({
        creators: ({ types }) => ({
            setMake: createAction(types.SET),
        }),
    });
}
