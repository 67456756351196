import {
    globalConfigsDuck,
} from '../ducks';
import getConfig from 'next/config';

export default function getConfigs() {
    const { publicRuntimeConfig } = getConfig();
    const { activEngageUrl } = publicRuntimeConfig;
    return async (ctx) => {
        ctx.store.dispatch(globalConfigsDuck.creators.setKeys({
            activEngageUrl,
        }));
    };
}
