import { openingHoursFormatter } from '.';

const brandFormatter = (data, makes) => {
    const result = [];
    if (data && data.length > 0 && makes?.length > 0) {
        data.forEach((dt) => {
            const make = makes.find((m) => m.value === dt);
            if (make) {
                result.push(make.label);
            }
        });
    }
    return result?.toString() || undefined;
};

const dealerSchemaFormatter = (data, owner, makes) => {
    const openingHours = openingHoursFormatter(owner);
    const { ownerServices, nameplate } = owner;
    const response = {
        '@context': data['@context'],
        '@type': data['@type'],
        name: data.name,
        url: owner.website?.href,
        image: data.image,
        '@id': `${data.image}#dealer-image`,
        telephone: data.telephone,
        description: data.description,
        brand: brandFormatter(nameplate, makes),
        priceRange: 'Get free price quote',
        address: data.address,
        aggregateRating: data.aggregateRating,
        geo: {
            '@type': 'GeoCoordinates',
            latitude: undefined,
            longitude: undefined,
        },
        openingHoursSpecification: openingHours,
    };
    if (ownerServices) {
        response.department = {
            '@type': 'AutoRepair',
            name: data.name,
            image: data.image,
            '@id': `${data.image}#service-center`,
            telephone: data.telephone,
            priceRange: '$-$$$$',
            address: data.address,
            openingHoursSpecification: openingHours,
        };
    }

    return response;
};

export default dealerSchemaFormatter;
