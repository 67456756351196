import { fetchServiceDealer } from '../fetchers';
import { serviceDealerDuck } from '../ducks';
import _get from 'lodash/get';

export default function getDealerService(state) {
    const serviceDealer = serviceDealerDuck.selectors.getDuckState(state);
    return async (ctx) => {
        const ownerId = _get(ctx, 'data.owner.id', 0);
        if (serviceDealer.data && ownerId === serviceDealer.ownerId) {
            ctx.data.serviceDealer = serviceDealer;
        } else {
            ctx.data.serviceDealer = await fetchServiceDealer(ownerId);
        }
    };
}
