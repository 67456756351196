import _get from 'lodash/get';
import { cloneSnakeCaseProperties } from 'reaxl-features';

// adds useFeatures functionality to the ctx object
export default function withUseFeatures() {
    return async (ctx) => {
        // looks up singular feature key
        ctx.useFeature = (key) => {

            // currently requires _app to have returned featureFlags from getInitialProps to work
            const { featureFlags = {} } = _get(ctx, 'appProps', {});
            const { config = {} } = featureFlags;

            // default feature to NOT enabled
            let isFeatureEnabled = false;
            let featureVariables = {};

            if (config[key]) {
                // if key existed, get it
                const { enabled: configEnabled, variables: configVariables = {} } = config[key];
                isFeatureEnabled = configEnabled;
                featureVariables = configVariables;
            }

            // duplicate snake_case property keys
            featureVariables = Object.entries(featureVariables).reduce(cloneSnakeCaseProperties, {});

            return [isFeatureEnabled, featureVariables];
        };
    };
}
