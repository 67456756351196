import PersonalizationEngineModuleCreator from '@atc/bonnet-module-creator-personalization-engine';
import _get from 'lodash/get';

const PersonalizationEngineModule = PersonalizationEngineModuleCreator({
    ssr: true,
    getApiQuery: (ctx) => {
        let language;

        if (typeof window === 'undefined') {
            language = _get(ctx.req, ['headers', 'accept-language']);
        } else {
            language = _get(window, 'navigator.language');
        }

        return {
            app: 'web',
            includeRaw: true,
            includeSources: 'consumerAdTargets,activeExperiments,consumerCampaignPreferences,consumerInsights,consumerAdTargetsWithoutCAMPBias,consumerMarketingSegments',
            'pc.locales': language ? language.substring(0, 2) : 'en',
        };
    },
});

export default PersonalizationEngineModule;
