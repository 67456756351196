import { pageNames } from '@atc/bonnet-paths';

const getAnalyticsPageName = (pageName, isKbb) => {
    let result = pageName;
    switch (pageName) {
        case pageNames.DEALER_DETAILS:
            result = (isKbb ? 'ddp' : 'fyd_ddp');
            break;
        case pageNames.DEALER_SEARCH:
            result = 'fyd';
            break;
        default: break;
    }
    return result;
};

export default getAnalyticsPageName;
