import _omit from 'lodash/omit';
import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';

import getSeoLocations from './getSeoLocations';
import getSeoMakes from './getSeoMakes';
import getSeoListingTypes from './getSeoListingTypes';

/*

The SEO links at the bottom of FYD use URLs created by the query parameters.
If a user is paginating through the search results, the 'firstRecord' parameter is added
to the URL for the SEO link and often leads to a "No Results Found" message for the user.

Using the Lodash _omit utility allows us to remove unnecessary parameters before building the href.

The seoQuery object is deleted from ctx after the href is built to prevent confusion with the primary query path.

*/

export default function getSeoCrawlpath() {
    return async (ctx) => {
        const currentState = ctx.store.getState();
        const { query } = ctx;
        ctx.seoQuery = _omit(query, 'firstRecord');

        await withCtxMiddleware([
            getSeoLocations(),
            getSeoMakes(currentState),
            getSeoListingTypes(),
        ], ctx);

        delete ctx.seoQuery;
    };
}
