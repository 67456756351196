import { fetchListingsAndFilters, fetchKbbRating } from '../fetchers';
import { removeEmptyMakeFilter, getInventorySortOption } from '../utilities';
import { set, get } from 'lodash';

function isShowInventory(data) {
    return data.listings?.length > 0;
}

export default function getListingsAndFilters() {
    return async (ctx) => {
        const [, { channel }] = ctx.useFeature('brand');
        const query = {
            ...ctx.query,
            stats: ['year', 'derivedprice'],
            channel: channel || ctx.data.brand.toUpperCase(),
        };
        const fetchOptions = {};
        const defaultSorttOption = getInventorySortOption(ctx.data.brand);
        const sortBy = !query.sortBy && { sortBy: defaultSorttOption };

        delete query.location;
        delete query.dma;

        if (ctx.req) {
            // add cookie to headers so we don't lose it on the server
            fetchOptions.headers = {
                cookie: ctx.req.headers.cookie,
            };
        }

        const data = await fetchListingsAndFilters({
            ...fetchOptions,
            // TODO: bonnet-atc - should this be pared down to only the query params we care about?
            query: {
                marketExtension: 'include',
                ...query,
                ...sortBy,
            },
        });
        if (ctx.data.lscOwner?.id) {
            data.owner = ctx.data.lscOwner;
            data.owners = [{ ...ctx.data.lscOwner }];
        }

        // repopulate rating value and rating count using Kbb Rating API
        if (data.owner && data.owner.rating) {
            const svocId = data.owner.id;
            const kbbRating = await fetchKbbRating(svocId);
            data.owner.rating.count = kbbRating.count;
            data.owner.rating.value = kbbRating.kbbDealerRating;
        }

        if (query.searchRadius < 50 || query.minPrice || query.maxPrice
            || query.startYear || query.endYear || query.mileage
            || query.fuelTypeGroup || query.doorCode || query.engineCode
            || query.featureCode || query.vehicleStyleCode || query.listingType
            || query.listingFeature || query.dealType || query.transmissionCode
            || query.driveGroup || query.extColorSimple || query.makeCode
            || query.mpgRange || query.intColorSimple
        ) {
            const noFilterData = await fetchListingsAndFilters({
                ...fetchOptions,
                query: {
                    marketExtension: 'include',
                    dealerId: query.dealerId,
                    channel: query.channel,
                },
            });
            const supportMakes = removeEmptyMakeFilter(noFilterData.filters?.makeCode.options);
            data.supportMakes = supportMakes;
            data.showInventory = isShowInventory(noFilterData);
        } else {
            const supportMakes = removeEmptyMakeFilter(data.filters.makeCode.options);
            data.supportMakes = supportMakes;
            data.showInventory = isShowInventory(data);
        }
        // if showInventory equals false then clear the inventory filters
        if (!data.showInventory) {
            set(data, 'filters', {});
        }

        const phoneNumber = get(data, 'listings.[0].owner.phone.value');
        // assign corrected phoneNumber from listing to owner
        if (phoneNumber && data.owner?.phone) {
            data.owner.phone = { ...data.owner.phone, value: phoneNumber };
        }

        // update the context data object with the response
        Object.assign(ctx.data, data);
    };
}
