import { fetchJSON } from '@bonnet/next/fetch';

export default async function fetchServiceDealer(svocid) {
    let isPayingDealer = false;
    const data = {};

    try {
        const response = await fetchJSON('/car-dealers/api/service-dealer', {
            search: { svocid },
            circuitBreaker: {
                timeout: 3000,
                fallback: {},
                id: 'service_dealer',
            },
        });
        const items = response.items;

        if (items && items.length > 0) {
            isPayingDealer = items.some((x) => x.isPayingDealer === true);
            data.phone = items[0].phone;
            data.departments = items[0].departments || [];
        }
    } catch (error) {
        console.log(error);
    }

    return { ownerId: svocid, isPayingDealer, data };
}
