import { authDuck } from '../ducks';

const signedInCookieName = 'MyConsumerSignedIn';

export default function processAuthState() {
    return async (ctx) => {
        let isLoggedIn = false;

        const {
            store,
            req,
        } = ctx;
        if (req) {
            if (req.cookies && req.cookies[signedInCookieName]) {
                store.dispatch(authDuck.creators.setLoggedIn());
                isLoggedIn = true;
            } else {
                store.dispatch(authDuck.creators.setLoggedOut());
            }
        }

        ctx.data = {
            ...ctx.data,
            isLoggedIn,
        };
    };
}
