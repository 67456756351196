import { pageNames } from '@atc/bonnet-paths';
import { objectDuckCreator } from '@atc/modular-redux';
import { DuckSelector, createSelector } from '@atc/modular-redux';
import { TAB_NAME } from '../constants';

export const getSelectedTabFromTabName = (tabs, tabName) => {
    const tab = tabs.find((t) => t.name === tabName);
    let selectedTab = {};
    if (tab) {
        selectedTab = { index: tabs.findIndex((t) => t.name === tab.name), name: tab.name };
    } else {
        selectedTab = { index: 0, name: tabs[0]?.name };
    }
    return selectedTab;
};

export default objectDuckCreator({
    namespace: pageNames.DEALER_DETAILS,
    store: 'tab',
    storePrefix: `${pageNames.DEALER_DETAILS}.`,
    initialState: {
        tabs: [],
        selected: {},
        dealerdetailstabs: '',
    },
}).extend({
    selectors: (duck) => ({
        isServiceTab: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (state) => state.selected.name === TAB_NAME.SERVICE
        )),
        getServiceTabIndex: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (state) => {
                const index = state.tabs.findIndex((tab) => tab.name === TAB_NAME.SERVICE);
                return index;
            }
        )),
    }),
    creators: (duck) => ({
        setSelectedTab: (index, name) => (dispatch) => {
            dispatch({
                type: duck.types.ASSIGN,
                payload: { selected: { index, name } },
            });
        },
        setTabs: (tabs, query) => (dispatch) => {
            const dealerdetailstabs = query.dealerdetailstabs;
            const state = duck.selectors.getDuckState();
            const tabName = state.selected.name ?? dealerdetailstabs;
            const selected = getSelectedTabFromTabName(tabs, tabName);
            dispatch({
                type: duck.types.ASSIGN,
                payload: { tabs, selected },
            });
        },
    }),
});
