/* global window */
import querystring from 'querystring';
import hasDom from 'has-dom';

// eslint-disable-next-line import/prefer-default-export
export function getPersonalizationClientEnv() {
    if (hasDom()) {
        const query = querystring.parse(window.location.search.slice(1));

        if (query.useGigyaDev) {
            return 'dev';
        }
        // TODO: replace with process.env.ENVIRONMENT when it works...
        if (['www.autotrader.com', 'speedy.autotrader.com'].includes(window.location.hostname)) {
            return 'prod';
        }
    }
    return 'qa';
}
