import { searchRadiusOptions } from './options';

export default {
    makeCode: {
        protected: true,
        label: 'Make Code',
        name: 'makeCode',
    },
    zip: {
        protected: true,
        label: 'ZIP Code',
        name: 'zip',
    },
    searchRadius: {
        protected: true,
        label: 'Distance',
        options: searchRadiusOptions,
        name: 'searchRadius',
    },
    minPrice: {
        collapsed: true,
        label: 'Min Price',
        name: 'minPrice',
    },
    maxPrice: {
        collapsed: true,
        label: 'Max Price',
        name: 'maxPrice',
    },
    startYear: {
        collapsed: true,
        label: 'Min Year',
        name: 'startYear',
    },
    endYear: {
        collapsed: true,
        label: 'Max Year',
        name: 'endYear',
    },
};
