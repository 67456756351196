import { filtersDuckCreator } from 'axl-ducks';
import { pageNames } from '@atc/bonnet-paths';
import { filtersReference } from '../reference';

export default filtersDuckCreator({
    namespace: pageNames.DEALER_SEARCH,
    storePrefix: `${pageNames.DEALER_SEARCH}.`,
    initialState: {
        options: filtersReference,
        values: {
            zip: {
                protected: true,
                value: null,
            },
            make: {
                protected: true,
                value: 'All Makes',
            },
        },
    },
});
