// original url https://assets.cai-media-management-np.com/dealership/d13e6ef2-ab1b-4072-ba3f-66df6ead7187.jpg
const getResizedImageUrl = (imageUrl, width = 680, height = 240) => {
    if (!width || width === 0 || !height || height === 0 || !imageUrl || imageUrl.length === 0) {
        return imageUrl;
    }

    const prodDomain = 'assets.cai-media-management.com';
    const npDomain = 'assets.cai-media-management-np.com';
    const resize = `/resize/${width}x${height}/`;

    let index = -1;

    index = imageUrl.indexOf(npDomain);
    if (index !== -1) {
        index += npDomain.length; // non prod domain detected
    } else {
        index = imageUrl.indexOf(prodDomain);
        if (index !== -1) {
            index += prodDomain.length; // prod domain detected
        } else {
            return imageUrl; // no match on the domain, cannot add resize parameter
        }
    }

    // add resize parameter to imageUrl
    if (index !== -1) {
        const finalUrl = imageUrl.slice(0, index) + resize + imageUrl.slice(index + 1);
        return finalUrl;
    }
};

export default getResizedImageUrl;
