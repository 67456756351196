/* global window */
import { paymentsDuckCreator } from 'axl-ducks';
import querystring from 'querystring';
import hasDom from 'has-dom';

function getPersonalizationClientEnv() {
    let env = 'qa';

    if (hasDom()) {
        const { location: { hostname, search } } = window;

        // TODO: I'd like to be able to use process.env.ENVIRONMENT...
        const isProd = ['www.autotrader.com', 'speedy.autotrader.com'].includes(hostname);

        if (isProd) {
            env = 'prod';
        } else {
            const query = querystring.parse(search.slice(1));

            if (query.useGigyaDev) {
                // useGigyaDev param is used for automation
                env = 'dev';
            }
        }
    }

    return { env };
}

const paymentsDuck = paymentsDuckCreator({
    store: 'payments',
    ...getPersonalizationClientEnv(),
});

export default paymentsDuck;
