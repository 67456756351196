import React from 'react';
import dynamic from 'next/dynamic';
import { isKbbBrand } from '../utilities';
import { AnalyticsProvider } from 'reaxl-analytics';
import useAnalyticsProps from '../analytics/useAnalyticsProps';

const KBBHeaderContainer = dynamic(() => import('./KBBHeaderContainer'));
const ATCHeaderContainer = dynamic(() => import('./ATCHeaderContainer'));

const HeaderContainer = (props) => {
    const {
        brand,
    } = props;

    const analyticsProps = useAnalyticsProps({ disablePostMessage: true });

    const Header = isKbbBrand(brand) ? KBBHeaderContainer : ATCHeaderContainer;

    return (
        <AnalyticsProvider {...analyticsProps}>
            <Header {...props} />
        </AnalyticsProvider>
    );
};

export default HeaderContainer;
