import { kbbBrand } from '../constants';
import { inventorySortOptions } from '../reference/options';

const getInventorySortOption = (brand) => {
    const priceLowestOption = inventorySortOptions.options[0];
    const yearNewestOption = inventorySortOptions.options[inventorySortOptions.options.length - 1];
    return brand === kbbBrand ? yearNewestOption.value : priceLowestOption.value;
};

export default getInventorySortOption;
