const groupDataByHour = (data) => data.reduce((group, product) => {
    const { open, close } = product;
    const groupName = `${open}-${close}`;
    group[groupName] = group[groupName] ?? [];
    group[groupName].push(product);
    return group;
}, {});

const openingHoursFormatter = (owner) => {
    const response = [];
    if (owner && owner.hours) {
        const groupData = groupDataByHour(owner.hours);
        for (const [key, value] of Object.entries(groupData)) {
            if (key && value.length > 0) {
                const data = {
                    '@type': 'OpeningHoursSpecification',
                    dayOfWeek: value.map((dt) => dt.label),
                    opens: value[0].open,
                    closes: value[0].close,
                };
                response.push(data);
            }
        }
    }
    return response;
};

export default openingHoursFormatter;
