export const searchRadiusOptions = ['10', '25', '50', '75', '100', '200', '300', '500', '0'].map((radius) => ({
    label: radius === '0' ? 'Any' : `${radius} Miles`,
    value: radius,
}));

export const inventorySortOptions = {
    label: '',
    name: 'numRecords',
    options: [
        {
            value: 'derivedpriceASC',
            label: 'Price - Lowest',
        },
        {
            value: 'derivedpriceDESC',
            label: 'Price - Highest',
        },
        {
            value: 'makemodelASC',
            label: 'Make/Model - A to Z',
        },
        {
            value: 'makemodelDESC',
            label: 'Make/Model - Z to A',
        },
        {
            value: 'mileageASC',
            label: 'Mileage - Lowest',
        },
        {
            value: 'mileageDESC',
            label: 'Mileage - Highest',
        },
        {
            value: 'yearASC',
            label: 'Year - Oldest',
        },
        {
            value: 'yearDESC',
            label: 'Year - Newest',
        },
    ],
};

export const dealerSortOptions = {
    label: '',
    name: 'numRecords',
    options: [
        {
            value: 'distanceASC',
            label: 'Distance - Closest',
        },
        {
            value: 'distanceDESC',
            label: 'Distance - Farthest',
        },
        {
            value: 'ownernameASC',
            label: 'Dealership Name - A-Z',
        },
        {
            value: 'ownernameDESC',
            label: 'Dealership Name - Z-A',
        },
    ],
};

export const numRecords = {
    label: '',
    name: 'numRecords',
    options: [
        {
            value: '10',
            label: 'Show 10',
        },
        {
            value: '25',
            label: 'Show 25',
        },
        {
            value: '50',
            label: 'Show 50',
        },
        {
            value: '75',
            label: 'Show 75',
        },
        {
            value: '100',
            label: 'Show 100',
        },
    ],
    value: '25',
};

export default {
    inventorySortOptions,
    dealerSortOptions,
    searchRadiusOptions,
    numRecords,
};
