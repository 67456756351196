import { unusedKBBMakes } from '../constants';

const enableKBBSeoMakes = (dealers) => {
    const restrictedSeoMakes = unusedKBBMakes;
    if (dealers?.length > 0) {
        restrictedSeoMakes.forEach((make) => {
            make.disabled = !dealers.some((d) => d.makeName?.toLowerCase() === make.makeName.toLowerCase());
        });
    }
    return restrictedSeoMakes;
};

export default enableKBBSeoMakes;
