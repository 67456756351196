import { getIndexedLocationFilters } from '@atc/bonnet-reference';
import { LOCATION_PRIORITY_HIGHEST } from '@atc/bonnet-reference-cache/constants';
import { atcBrand } from '../constants';

export default async function getCityZipCode(ownerLocation, brand = atcBrand, priority = LOCATION_PRIORITY_HIGHEST) {
    let cityCenterZip;
    const { state, city, zip: defaultZipCode } = ownerLocation;
    const { success, payload } = await getIndexedLocationFilters(ownerLocation, brand, priority);

    if (!state || !city) return defaultZipCode ?? '';

    if (success && payload) {
        cityCenterZip = payload.centerZip;
        return cityCenterZip;
    }

    return defaultZipCode;
}
