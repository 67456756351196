import { pageNames } from '@atc/bonnet-paths';
import { getPath } from '../utilities';

export default function processOwnerResults() {
    return async (ctx) => {
        let data = {
            urls: {},
            activeResults: [],
            inventory: [],
        };

        if (ctx.data.listings) {
            data = await ctx.data.listings.reduce(async (previous, listing) => {
                // we must resolve the previous async value
                const acc = await previous;

                // Generate a VDP url for each listing
                const { id: listingId } = listing;
                const path = await getPath(pageNames.VEHICLE_DETAILS, { listingId }, { req: ctx.req, referrer: true, brand: ctx.data.brand });
                acc.urls[listingId] = path;

                // Generate a VDP url for each home service link with anchor
                if (listing && listing.website) {
                    listing.website.href = path;
                }

                // add the owner and push to the inventory collection
                acc.inventory.push({ ...listing, owner: ctx.data.owner.id });

                // Push listing to active results
                acc.activeResults.push(listingId);

                return acc;
            }, Promise.resolve(data));

        }

        Object.assign(ctx.data, data);
    };
}
