export const getSelectedTabFromTabName = (tabs, tabName) => {
    const tab = tabs.find((t) => t.name === tabName);
    let selectedTab = {};
    if (tab) {
        selectedTab = { index: tabs.findIndex((t) => t.name === tab.name), name: tab.name };
    } else {
        selectedTab = { index: 0, name: tabs[0]?.name };
    }
    return selectedTab;
};

export default getSelectedTabFromTabName;
