const filterSeoMakes = (isKbb, makes, restrictedSeoMakes) => {
    let result = [];
    if (isKbb) {
        // makes is an array
        result = makes?.map((make) => {
            const remove = restrictedSeoMakes?.some((rm) => (rm.disabled && rm.makeName.toLowerCase() === make.makeName?.toLowerCase()));
            return (remove ? null : make);
        });
    } else {
        // makes is an object
        result = Object.values(makes || {})?.map((make) => {
            const remove = restrictedSeoMakes?.some((rm) => (rm.makeName.toLowerCase() === make.name?.toLowerCase()));
            return (remove ? null : make);
        });
    }

    return { ...result?.filter((rs) => rs !== null) };
};

export default filterSeoMakes;
