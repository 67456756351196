import { kbbHostUrl } from '../../constants';

/**
 *
 * @param {string} href Base URL
 * @param {boolean} hasDealerDetail Checks if the dealer as a Dealer Detail Page (DDP)
 * @param {string} fdpqWebsite Redirects user to this website if defined, which is used for FDPQ with the following format: ?manufacturername=${item.MakeName}&franchisekey=${(item.FranchiseId * 1000) + item.MakeId}, which sets the Make dropdown to the appropriate Make
 * @returns
 */
const createLinkForGetAPriceQuote = (href, hasDealerDetail, fdpqWebsite, dealerWebsiteUrl = undefined, dealerId = '', isKbb = false, makeName = '') => {
    const joinChar = href?.includes('?') ? '&' : '?';
    let salesTabParam = '';
    if (!dealerWebsiteUrl && isKbb) {
        return `${kbbHostUrl}/free-dealer-price-quote/?leadpartnerfranchiseid=${dealerId}&makename=${makeName}`;
    }
    if (hasDealerDetail) {
        salesTabParam = 'dealerdetailstabs=sales#sales';
        return `${href}${joinChar}${salesTabParam}`;
    }

    if (fdpqWebsite) {
        return fdpqWebsite;
    }

    return `${kbbHostUrl}/free-dealer-price-quote/`;
};

export default createLinkForGetAPriceQuote;
