import { pageNames } from '@atc/bonnet-paths';
import { stringDuckCreator, createAction } from '@atc/modular-redux';

export default stringDuckCreator({
    store: 'originPath',
    initialState: null,
    storePrefix: `${pageNames.DEALER_DETAILS}.`,
}).extend({
    creators: ({ types }) => ({
        setOrigin: createAction(types.SET),
    }),
});
