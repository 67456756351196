/* eslint-disable import/prefer-default-export,import/extensions */
import { fetchJSON } from '@bonnet/next/fetch';
import { defaultZipCode } from '../constants';

// @create-index
export { default as emailFetcher } from './emailFetcher.js';
export { default as fetchDealerLot } from './fetchDealerLot.js';
export { default as fetchKbbRating } from './fetchKbbRating.js';
export { default as fetchMakesFromSyndication } from './fetchMakesFromSyndication.js';
export { default as fetchServiceDealer } from './fetchServiceDealer.js';

const defaultCircuitBreakerOptions = {
    id: 'seo_location_service',
    timeout: 3000,
    fallback: {},
};

// This is high because the FYD solr calls are complex and can sometimes go over 3%.  Solr 8 upgrade should help then we will revisit
const lscCircuitBreakerOptions = {
    timeout: 6000,
    fallback: {},
};

export const fetchSeoLocation = (options) => fetchJSON('/car-dealers/api/seo-location-service', {
    defaultCircuitBreakerOptions,
    ...options,
});

export async function fetchOwnersFromBYC(request) {
    try {
        /**
         * Switches to either the endpoing with LSC enabled or the default if there is an override
         */
        const response = await fetchJSON('/car-dealers/byc/dealerlist', {
            data: {
                entryzip: request.zip || defaultZipCode,
                vehiclemake: request.makeCode || '',
                hostwebsite: request.channel || 'ATC',
                appName: 'fld',
            },
            circuitBreaker: {
                id: 'byc_dealerlist',
                timeout: 30000,
                fallback: {
                    Dealers: [],
                },
            },
        });
        return response.Dealers;
    } catch (e) {
        return [];
    }
}

export const fetchListingsAndFilters = async (options) => {
    try {
        const response = await fetchJSON('/rest/lsc/listing', {
            headers: {
                Accept: 'application/json',
            },
            lscCircuitBreakerOptions: { ...lscCircuitBreakerOptions, id: 'lsc_listing' },
            ...options,
        });
        return response;
    } catch (e) {
        return {};
    }
};
