export default function formatFilterFindDealerDetail() {
    return async (ctx) => {
        if (!ctx.data && !ctx.data.filters) {
            throw new Error('There is no required `ctx.data.filters` property containing the filter options.');
        } // extract the filters and filterGroups

        const { startYear, endYear } = ctx.query;

        // set endYear = startYear in case the startYear is greater than endYear
        if (startYear && ((startYear > endYear) || !endYear)) {
            ctx.query.endYear = startYear;
        }

        // remove query params if startYear = '0'
        if (!startYear && !endYear) {
            ctx.query.endYear = '0';
        } else if (!startYear || startYear === '0') {
            delete ctx.query.startYear;
        }
        delete ctx.query.searchRadius;
    };
}
