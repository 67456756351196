/* eslint-disable no-console */
import { fetchJSON } from '@bonnet/next/fetch';

export default async function fetchKbbRating(svocId) {
    const ratingsData = await fetchJSON('/car-dealers/awskbbdealerrating', {
        search: { svocId },
        circuitBreaker: {
            timeout: 3000,
            fallback: {},
            id: 'kbbdealerrating',
        },
    });
    if (ratingsData && ratingsData.data && ratingsData.data.providerRatings) {
        return ratingsData.data.providerRatings.find((rating) => rating.provider === 'KBB');
    }
    return { count: null, kbbDealerRating: null };
}
