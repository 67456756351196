import { pageNames } from '@atc/bonnet-paths';

const pattern = [
    /(dealers\/new(?:\/|\?|$))/im,
    /(dealers\/used(?:\/|\?|$))/im,
    /(dealers\/third-party-certified(?:\/|\?|$))/im,
    /(dealers\/all(?:\/|\?|$))/im,
    /(dealers\/certified(?:\/|\?|$))/im,
];

const isNoIndex = ({ pageName, pagePath, isKbb, hasInventory = false }) => {
    if (pageName === pageNames.DEALER_SEARCH) {
        return pagePath && pattern.some((reg) => reg.test(pagePath));
    }

    if (pageName === pageNames.DEALER_DETAILS) {
        return !isKbb && !hasInventory;
    }
    return false;
};

export default isNoIndex;
