import { filtersDuckCreator } from 'axl-ducks';

import { pageNames } from '@atc/bonnet-paths';
import { filtersReference } from '../reference';
import { defaultZipCode } from '../constants';

export default filtersDuckCreator({
    namespace: pageNames.DEALER_DETAILS,
    storePrefix: `${pageNames.DEALER_DETAILS}.`,
    initialState: {
        options: filtersReference,
        values: {
            zip: {
                protected: true,
                value: defaultZipCode,
            },
            searchRadius: {
                protected: true,
                value: 0,
            },
        },
        isReady: false,
    },
});
