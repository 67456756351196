import { decode } from '@atc/bonnet-seo';
import { fetchSeoLocation } from '../fetchers';
import _lowerCase from 'lodash/lowerCase';
import { dealerlistQuery } from '../constants';
import { getPath, getCityZipCode, transformsMake, filterDealerQuery } from '../utilities';

export default function getSeoLocations() {
    return async (ctx) => {

        const locations = await fetchSeoLocation({
            query: {
                state: ctx.query.state,
            },
        });

        const data = await Promise.all(locations.map(async ({ value, label }) => {
            const location = await decode(value, { param: 'location' });
            location.zip = await getCityZipCode(location);

            // remove unwanted parameters from the existing query string
            // eslint-disable-next-line no-unused-vars
            const { numRecords, firstRecord, sortBy, makeCode, dma, searchRadius, location: queryLocation, make, ...query } = ctx.query;
            const seoMakeCode = transformsMake(_lowerCase(make), true) ?? '';

            // Build a query object for the new url
            const _query = {
                ...query,
                ...location,
                seoMakeCode,
            };

            delete _query.make;
            delete _query.zip;

            const href = await getPath(ctx.pageName, filterDealerQuery(_query, dealerlistQuery), { brand: ctx.data.brand });
            return {
                href,
                label: label.replace(/\+/g, ' '),
            };
        }));

        // update the context data object with the response
        Object.assign(ctx.data, { seoLocations: data });
    };
}
