import { fetchJSON } from '@bonnet/next/fetch';
import { polestarMake, rivianMake, circuitBreakerOptions } from '../constants';

export default async function fetchMakesFromSyndication() {
    let result = [];
    const currentYear = new Date().getFullYear();
    const limit = 25;
    let start = 1;
    const enableCircuitBreak = process.env.ENABLE_CIRCUIT_BREAKER?.toLowerCase() === 'true';
    while (true) {
        // eslint-disable-next-line
        const makes = await fetchJSON('/car-dealers/syndication/makelist', {
            data: { limit, start, yearId: currentYear },
            circuitBreaker: enableCircuitBreak && circuitBreakerOptions,
        });
        if (Object.keys(makes).length === 0) {
            break;
        }
        result = result.concat(makes.items);
        if (result.length >= makes.count) {
            break;
        }
        start = limit + start;
    }
    return result.filter((make) => (make.makeName.toLowerCase() !== polestarMake && make.makeName.toLowerCase() !== rivianMake));

}
