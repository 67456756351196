import _get from 'lodash/get';
import { getReference } from '@atc/bonnet-reference';
import { getSyndicatedMakesFromState, getPath, filterSeoMakes, filterDealerQuery } from '../utilities';
import { kbbBrand, dealerlistQuery } from '../constants';

export default function getSeoMakes(state) {
    return async (ctx) => {
        const makeCode = [].concat(_get(ctx.query, 'makeCode', []));
        const hasMakeCode = makeCode.length > 0;
        const isKbb = (ctx.data.brand === kbbBrand);

        // remove unwanted parameters from the existing query string
        // eslint-disable-next-line no-unused-vars
        const { numRecords, firstRecord, sortBy, dma, searchRadius, location, ...query } = ctx.query;

        if (hasMakeCode && !isKbb) {
            // If there is at least one make code selected then we shouldn't create link data
            ctx.data.seoMakes = [];
        } else if (isKbb) {
            const makes = await getSyndicatedMakesFromState(state);
            const seoMakeFiltered = filterSeoMakes(isKbb, makes, ctx.data.restrictedSeoMakes);

            const data = await Promise.all(Object.keys(seoMakeFiltered).map(async (make) => {
                const { makeName: label } = seoMakeFiltered[make];

                // Build a query object for the new url
                const _query = {
                    ...query,
                    seoMakeCode: label.replace(' ', '-').toLowerCase(),
                };

                // handle any legacy query key
                delete _query.makeCodeList;
                delete _query.make;
                delete _query.makeCode;
                delete _query.zip;

                const href = await getPath(ctx.pageName, filterDealerQuery(_query, dealerlistQuery), { brand: ctx.data.brand });
                return { href, label };
            }));

            // update the context data object with the response
            ctx.data.seoMakes = data;
        } else {
            const { payload: makes = {} } = await getReference('makeCode');
            const seoMakeFiltered = filterSeoMakes(isKbb, makes, ctx.data.restrictedSeoMakes);

            const data = await Promise.all(Object.keys(seoMakeFiltered).map(async (make) => {
                const { name: label, code } = seoMakeFiltered[make];

                // Build a query object for the new url
                const _query = {
                    ...query,
                    makeCode: code,
                };

                // handle any legacy query key
                delete _query.makeCodeList;
                delete _query.zip;

                const href = await getPath(ctx.pageName, filterDealerQuery(_query, dealerlistQuery), { brand: ctx.data.brand });
                return { href, label };
            }));

            // update the context data object with the response
            ctx.data.seoMakes = data;
        }
    };
}
