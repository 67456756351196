import { createAction, objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    namespace: '',
    store: 'birf',
    initialState: {
        pageData: {},
    },
}).extend({
    creators: (duck) => ({
        setState: createAction(duck.types.SET_STATE),
    }),
    selectors: {
        getPgInst: (state) => state?.birf?.pageData?.page?.BIRF?.pg_inst || '',
    },
    reducer: (state, action, { types }) => {

        if (action.type === types.SET_STATE) {
            return { pageData: action.payload.dataIsland || {} };
        }
        return state;
    },
    types: [
        'SET_STATE',
    ],
});
