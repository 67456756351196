import { fetchJSON } from '@bonnet/next/fetch';
import { dealerSetMakeDuck } from '../ducks';
import { allMakeValue, circuitBreakerOptions } from '../constants';

const getMakeByMakeName = async (makeName = '') => {
    const enableCircuitBreak = process.env.ENABLE_CIRCUIT_BREAKER?.toLowerCase() === 'true';
    const { items } = await fetchJSON('/car-dealers/syndication/makelist', {
        data: { makeName },
        circuitBreaker: enableCircuitBreak && circuitBreakerOptions,
    });
    return items?.length > 0 ? items[0] : null;
};

export default function setKbbMakeFromQuery() {
    return async (ctx) => {
        if (ctx.query.slug?.length > 1) {
            const makeSegment = ctx.query.slug[1];
            let mapedMake = ctx.data.makeList
                .find((make) => makeSegment === make.makeName.toLowerCase().replace(' ', '-'));

            if (!mapedMake) {
                const makeItem = await getMakeByMakeName(makeSegment);
                if (makeItem) {
                    ctx.data.makeList.push(makeItem);
                    mapedMake = makeItem;
                }
            }

            if (mapedMake) {
                ctx.query.make = mapedMake.makeName;
                ctx.query.makeCode = mapedMake.atcMake.code;
                ctx.query.seoMakeCode = ctx.query.make.replace(' ', '-').toLowerCase();
            }
        }

        ctx.store.dispatch(
            dealerSetMakeDuck.creators.setMake(ctx.query.make ? ctx.query.make : allMakeValue)
        );
    };
}
