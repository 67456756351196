import _get from 'lodash/get';
import { inventoryActiveOwnerDuck, lscOwnerDuck } from '../ducks';
import fetchLscOwner from '../fetchers/fetchLscOwner';

const getLscOwner = async (query, state) => {
    const dealerId = _get(query, 'dealerId');
    const zipCode = _get(query, 'zip');
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const ownerId = dealerId || activeOwner;
    const lscOwner = lscOwnerDuck.selectors.getDuckState(state);
    if (lscOwner.id) {
        return lscOwner;
    }
    const response = await fetchLscOwner(ownerId, zipCode);
    return response;
};

export default getLscOwner;
