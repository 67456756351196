import { EMAIL_SUBJECT } from '../constants';

const transformPixallEmailLabel = (emailSubject = '') => {
    switch (emailSubject.toLowerCase()) {
        case EMAIL_SUBJECT.GENERAL_VEHICLE_INFO.value.toLowerCase():
            return EMAIL_SUBJECT.GENERAL_VEHICLE_INFO.label;

        case EMAIL_SUBJECT.LOCAL_HOME_DELIVERY.value.toLowerCase():
            return EMAIL_SUBJECT.LOCAL_HOME_DELIVERY.label;

        case EMAIL_SUBJECT.REQUEST_A_TEST_DRIVE.value.toLowerCase():
            return EMAIL_SUBJECT.REQUEST_A_TEST_DRIVE.label;

        case EMAIL_SUBJECT.TRADE_IN_INFO.value.toLowerCase():
            return EMAIL_SUBJECT.TRADE_IN_INFO.label;

        case EMAIL_SUBJECT.VEHICLE_SERVICE_AND_REPAIR.value.toLowerCase():
            return EMAIL_SUBJECT.VEHICLE_SERVICE_AND_REPAIR.label;

        case EMAIL_SUBJECT.AVAILABILITY_OF_VEHICLE.value.toLowerCase():
            return EMAIL_SUBJECT.AVAILABILITY_OF_VEHICLE.label;

        default:
            return EMAIL_SUBJECT.GENERAL_VEHICLE_INFO.label;
    }
};

export default transformPixallEmailLabel;
