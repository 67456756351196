import _get from 'lodash/get';
import { fetchMakesFromSyndication } from '../fetchers';
import { dealerCrawlPathDuck } from '../ducks';

const getSyndicatedMakesFromState = async (state) => {
    const crawlPathData = dealerCrawlPathDuck.selectors.getDuckState(state);
    let currentMakeList = _get(crawlPathData, 'makeList', []);
    if (currentMakeList.length === 0) {
        try {
            currentMakeList = await fetchMakesFromSyndication();
        } catch (error) {
            console.log(error);
        }
    }

    return currentMakeList;
};

export default getSyndicatedMakesFromState;
