import { createAction, stringDuckCreator } from '@atc/modular-redux';

export default function lscOwnerCreator({
    namespace = '',
    store = 'lscOwner',
    storePrefix,
    initialState = {},
} = {}) {
    return stringDuckCreator({
        initialState,
        namespace,
        store,
        storePrefix,
    }).extend({
        creators: ({ types }) => ({
            setValue: createAction(types.SET),
        }),
    });
}
