export default function withFeatures() {
    return async (ctx) => {
        try {
            if (!ctx.data.configFeatures && typeof window === 'undefined') {
                const { default: features } = await import(`../features/${ctx.data.brand}.js`);
                ctx.data.configFeatures = features;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('withFeatures - ', error);
        }
    };
}
