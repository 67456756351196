import { useLayoutEffect, useState } from 'react';

export default function getPageScrollPercentage() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [scrollPercent, setScrollPercent] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
        function getScrollPercent() {
            const scrollTop = window.scrollY;
            const documentHeight = document.body.offsetHeight;
            const windowHeight = window.innerHeight;
            const scrollPercentValue = scrollTop / (documentHeight - windowHeight);
            const scrollPercentRounded = Math.round(scrollPercentValue * 100);
            setScrollPercent(scrollPercentRounded);
        }
        window.addEventListener('scroll', getScrollPercent);
        getScrollPercent();
        return () => window.removeEventListener('scroll', getScrollPercent);
    }, []);
    return scrollPercent;
}
