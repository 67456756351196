import getConfig from 'next/config';

export default () => {
    const { publicRuntimeConfig } = getConfig();

    return [
        {
            match: '^(?!/car-dealers).*',
            domain: publicRuntimeConfig.atcHost.replace(/\/$/, ''),
        },
    ];
};
