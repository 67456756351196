import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { kbbBrand } from '../constants';

import { atcTheme, kbbTheme } from '@argo/themes';

export default ({ brand = 'atc', ...props }) => (
    <ThemeProvider
        theme={brand === kbbBrand ? kbbTheme : atcTheme}
        {...props}
    />
);
