import { userDuckCreator } from 'axl-ducks';
import inventoryDuck from './inventoryDuck';
import savedInventoryDuck from './savedInventoryDuck';

import { getPersonalizationClientEnv } from './duckUtils';

export default userDuckCreator({
    inventoryDuck,
    savedInventoryDuck,
    env: getPersonalizationClientEnv(),
});
