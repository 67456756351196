/* global window */
import hasDom from 'has-dom';

export default function redirectExternal(url) {
    if (hasDom()) {
        return window.location.assign(url);
    }

    return false;

}
