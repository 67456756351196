const transformsMake = (make = '', IsSeo = false) => {
    switch (make.toLowerCase()) {
        case 'mc laren':
            return 'McLaren';

        case 'de lorean':
            return 'DeLorean';

        case 'alfa romeo':
            return IsSeo ? 'alfa-romeo' : make;

        case 'aston martin':
            return IsSeo ? 'aston-martin' : make;

        case 'land rover':
            return IsSeo ? 'land-rover' : make;

        case 'mercedes benz':
            return IsSeo ? 'mercedes-benz' : make;

        case 'rolls royce':
            return IsSeo ? 'rolls-royce' : make;

        default:
            return make;
    }
};

export default transformsMake;
