import { kbbBrand } from '../constants';

const isKbbBrand = (brand) => {
    if (brand?.toLowerCase() === kbbBrand) {
        return true;
    }
    return false;
};

export default isKbbBrand;
