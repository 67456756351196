import { pageNames } from '@atc/bonnet-paths';
import { atcBrand, previewAtcBrand } from '../constants';

const configData = {
    atc: (pageName) => {
        if (pageName === pageNames.DEALER_SEARCH) {
            return {
                pgId: undefined,
                pgData: 'fyd_srl',
            };
        }
        return {
            pgId: undefined,
            pgData: 'fyd_ddp',
        };
    },
    kbb: (pageName) => {
        if (pageName === pageNames.DEALER_SEARCH) {
            return {
                pgId: 'rtbdlrlist',
                pgData: 'rtbdlrlist',
            };
        }
        return {
            pgId: 'dlrinfoinvovrnew',
            pgData: 'dlrinfoinvovrnew',
        };
    },
};

export default function getDataIsland(ctx) {
    const { pageName, query, data: { brand } } = ctx;
    const { pgId, pgData } = configData[brand === previewAtcBrand ? atcBrand : brand](pageName);
    return {
        page: {
            site: {
                superSection: '',
                section: 'find-your-dealer',
                pageName,
                domainKey: pageName,
                detailedPageName: pageName === pageNames.DEALER_SEARCH ? 'srp_dealer' : 'ddp',
            },
            location: {
                ipZipCode: '',
                dma: query.dma?.code,
                distance: '',
            },
            vehicle: {
                body_code: '', // VDP
                car_year: '', // VDP
                car_id: '', // VDP
                fuel: '', // VDP
                model: query.modelCode,
                trim: query.trimCode,
                price: '', // VDP
                search_max_price: query.maxPrice,
                search_max_year: query.endYear,
                search_min_price: query.minPrice,
                search_min_year: query.startYear,
                search_type: [

                ],
                Car_Type: query.listingType,
                search_distance: query.searchRadius,
                color: query.extColorSimple,
                chromeStyleId: '',
                car_ids: query.carIds,
            },
            owner: {
                dealer_id: query.ownerId,
                seller_type: '',
            },
            demographic: {
            },
            BIRF: {
                logDomain: 'https://www.autotrader.com',
                pg: pgData,
                pn: ctx.data.currentPage - 1,
                tlc: ctx.data.totalResultCount,
                crd_lvl: '',
                pg_id: pgId,
            },
            doubleclick: {
                sptlght: {
                    SRC: '3003809',
                    Type: 'fycdi558',
                    CAT_SF: 'searc299',
                    CAT_SRP: 'srpbh529',
                    CAT_VDP: 'vdpzz092',
                    ORD: Math.floor(1000000000 + Math.random() * 9000000000),
                },
                fldlght: {
                    SRC: '3135983',
                    Type: 'autot826',
                    CAT_SF: 'findy641',
                    CAT_SRP: 'searc192',
                    CAT_VDP: 'vehic678',
                    ORD: Math.floor(1000000000 + Math.random() * 9000000000),
                },
            },
        },
    };
}
