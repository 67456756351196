import { ddpClickType } from '../../constants';

const createDdpLink = (isKbb, href, isPayingDealer, clickType) => {
    const joinChar = href?.includes('?') ? '&' : '?';
    let tabParam = '';
    if (isPayingDealer && clickType === ddpClickType.SCHEDULE_CLICK) {
        tabParam = 'dealerdetailstabs=service#service';
    } else if (isPayingDealer && isKbb && clickType === ddpClickType.DEALER_CARD_CLICK) {
        tabParam = 'dealerdetailstabs=reviews#reviews';
    }
    const ret = href + (tabParam && `${joinChar}${tabParam}`);
    return ret;
};

export default createDdpLink;
