const resetTab = (router, tabName) => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('dealerdetailstabs') || window.location.search.split('#')[1]) {
        params.set('dealerdetailstabs', tabName);
    } else {
        params.append('dealerdetailstabs', tabName);
    }
    window.history.pushState({}, null, `?${params.toString()}#${tabName}`);
};
export default resetTab;
