const filterDealerQuery = (query, whitelist) => {
    if (query && whitelist?.length > 0) {
        const result = {};
        Object.entries(query).forEach(([key, value]) => {
            if (whitelist.find((item) => item === key)) {
                result[key] = value;
            }
        });
        return result;
    }
    return query;
};

export default filterDealerQuery;
