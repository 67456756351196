import _get from 'lodash/get';
import { getReference } from '@atc/bonnet-reference';
import { getPath } from '../utilities';

export default function getSeoListingTypes() {
    return async (ctx) => {
        // Create an array of seo links for makes
        const listingType = [].concat(_get(ctx.query, 'listingType', []));
        const hasListingType = listingType.length > 0;

        if (hasListingType) {
            // If there is at least one listing type selected then we shouldn't create link data
            ctx.data.seoListingTypes = [];
        } else {

            const { payload: listingTypes = [] } = await getReference('listingType');

            // remove unwanted parameters from the existing query string
            // eslint-disable-next-line no-unused-vars
            const { numRecords, firstRecord, sortBy, makeCode, dma, searchRadius, location, ...query } = ctx.query;

            const data = await Promise.all(listingTypes.map(async ({ name, code }) => {

                // Build a query object for the new url
                const _query = {
                    ...query,
                    listingType: code,
                };

                // handle any legacy query key
                delete _query.listingTypes;

                const href = await getPath(ctx.pageName, _query, { brand: ctx.data.brand });
                return {
                    key: code,
                    href,
                    label: `${name} Car Dealers`,
                };
            }));

            // update the context data object with the response
            ctx.data.seoListingTypes = data;

        }
    };
}
