import { createAction, objectDuckCreator } from '@atc/modular-redux';
import { pageNames } from '@atc/bonnet-paths';

export default objectDuckCreator({
    namespace: pageNames.DEALER_DETAILS,
    store: 'retainOwner',
    storePrefix: `${pageNames.DEALER_DETAILS}.`,
    initialState: {
        id: null,
        name: undefined,
    },
}).extend({
    creators: (duck) => ({
        setRetainOwner: createAction(duck.types.SET_STATE),
    }),
    reducer: (state, action, { types }) => {

        if (action.type === types.SET_STATE && state.id !== action.payload.id) {
            return action.payload;
        }
        return state;
    },
    types: [
        'SET_STATE',
    ],
});
