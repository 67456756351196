import isKbbBrand from './isKbbBrand';

const appendSlash = (path, brand = 'atc') => {
    if (!isKbbBrand(brand)) return path;

    if (path.includes('?') && !path.includes('/?')) {
        path = path.replace('?', '/?');
    }

    if (!path.includes('?') && !path.endsWith('/')) {
        path += '/';
    }
    return path;
};

export default appendSlash;
